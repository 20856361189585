'use client';

import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';

import { PropsWithChildren } from 'react';

import Sidebar from '@/app/[locale]/(auth)/_components/sidebar';
import Logo from '@/app/_components/header/components/logo';
import {
  Drawer,
  DrawerTrigger,
  DrawerContent,
  DrawerHeader,
} from '@/app/_components/ui/drawer';
import { useBreakpoint } from '@/lib/style/hooks';

interface LayoutProps extends PropsWithChildren {
  sideTitle: string;
  side: React.ReactNode;
  cta: React.ReactNode;
  back?: React.ReactNode;
}

const Layout = ({ sideTitle, side, cta, back, children }: LayoutProps) => {
  const isDesktop = useBreakpoint('lg');

  return isDesktop ? (
    <div className="grid h-screen grid-cols-5">
      <div className="col-span-2">
        <Sidebar title={sideTitle}>{side}</Sidebar>
      </div>

      <div className="col-span-3 flex grow flex-col justify-center gap-8 px-16 py-8">
        {back}
        {children}
        {cta}
      </div>
    </div>
  ) : (
    <div className="flex h-screen flex-col">
      <Drawer>
        <header className="flex w-full justify-between border-b p-6">
          {back}
          <Logo />
          <DrawerTrigger className="flex size-8 items-center justify-center rounded-full bg-primary text-white">
            <QuestionMarkCircledIcon className="size-6" />
          </DrawerTrigger>
        </header>
        <div className="flex grow flex-col justify-center p-4">{children}</div>
        <footer className="flex flex-col items-center gap-4 border-t p-6">
          {cta}
        </footer>
        <DrawerContent className="flex flex-col gap-4 p-6 pb-20">
          <DrawerHeader>
            <h3 className="text-xl font-semibold">{sideTitle}</h3>
          </DrawerHeader>
          {side}
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default Layout;
