import { EyeClosedIcon, EyeOpenIcon } from '@radix-ui/react-icons';
import * as React from 'react';

import { cn } from '@/lib/utils';

import { useFormField } from './form';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ReactNode;
  iconClassName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, icon, iconClassName, ...props }, ref) => {
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
    const { error } = useFormField();
    const input = (
      <input
        type={isPasswordVisible ? 'text' : type}
        className={cn(
          'flex h-12 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring',
          className,
          type === 'password' && 'relative pr-10',
          icon && 'pr-6 lg:pr-8',
          error && 'border-destructive',
          props.hidden && 'hidden',
          props.readOnly &&
            'read-only:cursor-not-allowed read-only:bg-muted-foreground/25 read-only:opacity-50',
        )}
        ref={ref}
        {...props}
      />
    );

    if (type === 'password') {
      return (
        <div className="relative">
          {input}
          {type === 'password' ? (
            <button
              className="absolute inset-y-0 right-4"
              type="button"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            >
              {isPasswordVisible ? <EyeOpenIcon /> : <EyeClosedIcon />}
            </button>
          ) : null}
        </div>
      );
    }

    if (icon) {
      return (
        <div className="relative">
          {input}
          <div
            className={cn(
              'absolute inset-y-0 right-2 my-auto h-fit lg:right-4',
              iconClassName,
            )}
          >
            {icon}
          </div>
        </div>
      );
    }

    return input;
  },
);
Input.displayName = 'Input';

export { Input };
