'use client';

import { useTranslations } from 'next-intl';

import { type TFunction } from '@/lib/translation/config';

const getTranslationList = (
  t: TFunction,
  listKey: string,
  listLength: number,
): string[] => {
  return Array.from(Array(listLength)).map((_, i) => t(`${listKey}.${i}`));
};

const SideContent = () => {
  const t = useTranslations();
  return (
    <ul className="flex list-inside list-disc flex-col gap-4">
      {getTranslationList(t, 'pages.signIn.sidebar.items', 3).map(
        (item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>{item}</li>
        ),
      )}
    </ul>
  );
};

export default SideContent;
