import { motion } from 'framer-motion';
import Image from 'next/image';

import { PropsWithChildren } from 'react';

import Logo from '@/app/_components/header/components/logo';
import { Icons } from '@/app/_components/ui/icons';

export interface SidebarProps extends PropsWithChildren {
  title: string;
  illustrationSrc?: string;
  tooltip?: string;
}

const Sidebar = ({
  title,
  children,
  illustrationSrc,
  tooltip,
}: SidebarProps) => {
  return (
    <div className="flex size-full flex-col justify-between bg-primary-100 px-8 py-16 lg:px-10 xl:px-20">
      <div className="relative flex h-full flex-col gap-y-16 lg:justify-center">
        <div className="absolute left-0 top-0">
          <Logo />
        </div>

        <div className="mt-28 flex flex-col gap-y-10 lg:mt-32 xl:mt-24">
          <motion.h1
            initial={{ transform: 'translateX(50%)', opacity: 0 }}
            animate={{ transform: 'translateX(0)', opacity: 1 }}
            className="font-heading text-3xl font-semibold text-primary"
          >
            {title}
          </motion.h1>

          <motion.div
            initial={{ transform: 'translateX(25%)', opacity: 0 }}
            animate={{ transform: 'translateX(0)', opacity: 1 }}
            transition={{ delay: 0.1 }}
            className="flex flex-col gap-8"
          >
            {children}

            {illustrationSrc ? (
              <div className="relative hidden h-64 w-full lg:block">
                <Image
                  src={illustrationSrc}
                  alt=""
                  role="presentation"
                  // It's rendered in a 2 col-span in a grid-cols-5 hence
                  sizes="40vw"
                  fill
                  className="object-contain"
                />
              </div>
            ) : null}
          </motion.div>
        </div>

        {tooltip ? (
          <motion.div
            initial={{ transform: 'scale(0)', opacity: 0 }}
            animate={{ transform: 'scale(1)', opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="absolute inset-x-0 bottom-0 flex origin-bottom flex-row items-center gap-x-4 rounded-2xl bg-primary px-6 py-4 text-white"
          >
            <Icons.Information className="size-6 shrink-0" />
            <p className="text-sm">{tooltip}</p>
          </motion.div>
        ) : null}
      </div>
    </div>
  );
};

export default Sidebar;
