import { UseFormReturn } from 'react-hook-form';

import { SafeParseError } from 'zod';

import { ActionStateErrors, InputData, RHFErrorsLike } from './type';

export function setFormErrors<T extends InputData>(
  errors: ActionStateErrors<T>,
  form: UseFormReturn<T>,
) {
  Object.entries(errors).forEach(([path, message]) => {
    // We cannot say to Typescript that path is already typed to keyof T + root
    form.setError(path as RHFErrorsLike<T>, {
      message,
    });
  });
}

export function mapZodErrorsToActionFormErrors<T extends InputData>(
  zodErrors: SafeParseError<T>,
  fieldsToPassAsRoot?: Array<keyof Partial<T>>,
): ActionStateErrors<T> {
  return zodErrors.error.issues.reduce((errors, issue) => {
    const key = issue.path.join('.');
    const keyPassed = fieldsToPassAsRoot?.includes(key) ? 'root' : key;
    return {
      ...errors,
      [keyPassed]: issue.message,
    };
  }, {});
}
